/*
 * @Author: Custer
 * @Date: 2021-11-03 11:32:27
 * @LastEditors: Custer
 * @LastEditTime: 2021-12-06 14:26:37
 * @Description: file content
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/custer/layouts';
import { routerMode, publicPath } from '@/config';


Vue.use(VueRouter);

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    meta: {
      hidden: true,
    },
  },
  {
    path: "/403",
    component: () => import('@/views/403'),
    meta: {
      hidden: true,
    },
  },
  {
    path: "/404",
    component: () => import('@/views/404'),
    meta: {
      hidden: true,
    },
  }
]

export const baseRoutes = [
  {
    path: '/',
    name: 'Root',
    component: Layout,
    redirect: "/index",
    meta: {
      title: '首页',
      icon: 'home-2-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: '@/views/index/dashboard',
        meta: {
          title: '看板',
          icon: 'dashboard-line',
          noClosable: true,
        },
      },
      {
        path: "permission",
        name: 'Permission',
        component: '@/views/index/permission',
        meta: {
          title: '角色权限',
          icon: 'user-3-line',
        }
      }
    ],
  },
]

const router = createRouter();

function fatteningRoutes(routes) {
  return routes.flatMap((route) => {
    return route.children ? fatteningRoutes(route.children) : route
  })
}

export function resetRouter(routes = constantRoutes) {
  routes.map((route) => {
    if (route.children) {
      route.children = fatteningRoutes(route.children)
    }
  })
  router.matcher = createRouter(routes).matcher
}

function createRouter(routes = constantRoutes){
  return new VueRouter({
    base: publicPath,
    mode: routerMode,
    routes
  })
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router;
