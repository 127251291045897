/*
 * @Author: Custer
 * @LastEditors: zhongzhaoli 525966315@qq.com
 * @Date: 2022-10-23 21:01:45
 * @LastEditTime: 2022-10-23 21:03:14
 * @Description: file content
 */
import Vue from "vue";
const filters = {
    /**
     * 金钱单位置换 200 -> 2.00
    */
    unitPrice: (val) => {
        if (!val) return parseFloat(0).toFixed(2);
        let newVal = parseFloat(val / 100).toFixed(2)
        return newVal
    }
}

Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});
