<!--
 * @Author: Custer
 * @Date: 2021-12-14 11:31:48
 * @LastEditors: zhongzhaoli 525966315@qq.com
 * @LastEditTime: 2022-10-18 00:10:37
 * @Description: file content
-->
<template>
  <div class="custer-iconPicker">
    <el-popover trigger="click" width="400">
      <template #reference>
        <el-input readonly v-model="formValue" :placeholder="placeholder">
          <template slot="prepend" v-if="formValue">
            <CusterIcon :iconName="formValue"></CusterIcon>
          </template>
        </el-input>
      </template>
      <template>
        <el-row :gutter="10">
          <el-col :span="20" style="margin-bottom: 10px">
            <el-input
              v-model="keyWord"
              placeholder="查询想要的图标"
              size="small"
              @change="searchFun"
            ></el-input>
          </el-col>
          <el-col :span="4">
            <el-button
              type="primary"
              size="small"
              style="width: 100%"
              @click="searchFun"
              >查询</el-button
            >
          </el-col>
        </el-row>
        <el-row :gutter="10" class="mt-10">
          <el-col :span="6" v-for="item in showList" :key="item">
            <div class="iconBox" @click="handle(item)">
              <CusterIcon style="font-size: 30px" :iconName="item"></CusterIcon>
            </div>
          </el-col>
        </el-row>
        <el-pagination
          style="margin-top: 0"
          layout="total, prev, next"
          background
          :total="limitList.length"
          :current-page.sync="pageNum"
          :page-size="pageSize"
          @current-change="pageChange"
        ></el-pagination>
      </template>
    </el-popover>
  </div>
</template>
<script>
import iconList from "./config";
export default {
  name: "CusterIconPicker",
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "请选择图标",
    },
  },
  watch: {
    formValue: {
      deep: true,
      immediate: true,
      handler(nV) {
        this.$emit("input", nV);
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler(nV) {
        this.formValue = nV;
      },
    },
  },
  data() {
    return {
      iconList,
      limitList: [],
      pageNum: 1,
      pageSize: 12,
      showList: [],
      keyWord: "",
      formValue: "",
    };
  },
  created() {
    this.limitList = JSON.parse(JSON.stringify(this.iconList));
    this.getShowList();
  },
  methods: {
    searchFun() {
      let list = this.keyWord
        ? this.iconList.filter(item => item.indexOf(this.keyWord) >= 0)
        : JSON.parse(JSON.stringify(this.iconList));
      this.pageNum = 1;
      this.pageSize = 16;
      this.limitList = list;
      this.getShowList(list);
    },
    pageChange(val) {
      this.pageNum = val;
      this.getShowList();
    },
    getShowList(list = this.limitList) {
      const newIconList = JSON.parse(JSON.stringify(list));
      let start = (this.pageNum - 1) * 16;
      this.showList = newIconList.splice(start, 16);
    },
    handle(val) {
      this.formValue = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.iconBox {
  border: 1px #eaeaea solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 10px 0;
}
</style>
