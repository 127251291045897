/*
 * @Author: Custer
 * @Date: 2021-11-04 16:13:31
 * @LastEditors: zhongzhaoli 525966315@qq.com
 * @LastEditTime: 2023-05-20 22:28:20
 * @Description: file content
 */

/**
 * @description 判读是否为外链
 * @param path
 * @returns {boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:|\/\/)/.test(path)
}

/**
 * @description 判断是否为json
 * @param value
 * @returns {boolean}
 */
export function isJson(value) {
  if (typeof value === 'string') {
    const obj = JSON.parse(value)
    return !!(typeof obj === 'object' && obj)
  }
  return false
}

/**
 * @description 判断是否是字符串
 * @param value
 * @returns {boolean}
 */
export function isString(value) {
  return typeof value === 'string' || value instanceof String
}

/**
 * @description 判断是否是数组
 * @param arg
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
 * 判断是否为空
 * @param value
 * @returns {boolean}
 */
export function isEmpty(value) {
  return typeof (value) == 'undefined' || value == null || value === 'null' || value === undefined || value === 'undefined' || value === ''
}


/**
 * 生成唯一ID
 */
export function generateRandomCode() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let code = '';
  for (let i = 0; i < 10; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters[randomIndex];
  }
  return code;
}


/**
  * 二次确认框
  * @param title
  * @param api
  * @param key
  */
export function confirm(self, title = "确实进行此操作？", API, KEY, data = {}, sucFun = () => { }, errFun = () => { }) {
  self.$confirm(title, "提示", {
    type: "warning",
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    beforeClose: async (action, ctx, close) => {
      if (action !== "confirm") {
        errFun();
        close();
        return;
      }
      ctx.confirmButtonLoading = true;
      try {
        await API[KEY](data);
        self.$message.success("操作成功");
        sucFun();
        close();
      } catch (err) {
        close();
        throw err;
      } finally {
        ctx.confirmButtonLoading = false;
      }
    },
  });
}