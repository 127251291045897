/*
 * @Author: Custer
 * @Date: 2021-11-15 14:50:31
 * @LastEditors: zhongzhaoli 525966315@qq.com
 * @LastEditTime: 2022-10-18 00:21:02
 * @Description: file content
 */
import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/router/getList',
    method: 'get',
    params,
  })
}

export function getListForSet(params){
  return request({
    url: "/router/getListForSet",
    method: "get",
    params
  })
}

export function editRouter(data){
  return request({
    url: "/router/editRouter",
    method: "post",
    data
  })
}

export function delRouter(id){
  return request({
    url: "/router/deleteRouter",
    method: "post",
    data: {
      id
    }
  })
}