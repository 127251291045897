/*
 * @Author: Custer
 * @Date: 2021-10-29 14:29:05
 * @LastEditors: Custer
 * @LastEditTime: 2021-11-05 12:02:14
 * @Description: file content
 */
module.exports = {
  首页: '首页',
  看板: '看板',
  工作台: '工作台',
  更新日志: '更新日志',
  配置: '配置',
  个人中心: '个人中心',
  用户管理: '用户管理',
  角色管理: '角色管理',
  部门管理: '部门管理',
  菜单管理: '菜单管理',
  系统日志: '系统日志',
  组件: '组件',
  其他: '其他',
  角色权限: '角色权限',
  外链: '外链',
  图标: '图标',
  常规图标: '常规图标',
  小清新图标: '小清新图标',
  图标选择器: '图标选择器',
  表格: '表格',
  综合表格: '综合表格',
  行内编辑表格: '行内编辑表格',
  自定义表格: '自定义表格',
  表单: '表单',
  综合表单: '综合表单',
  分步表单: '分步表单',
  按钮: '按钮',
  文字链接: '文字链接',
  单选框: '单选框',
  多选框: '多选框',
  输入框: '输入框',
  计数器: '计数器',
  选择器: '选择器',
  开关: '开关',
  滑块: '滑块',
  时间选择器: '时间选择器',
  日期选择器: '日期选择器',
  日期时间选择器: '日期时间选择器',
  评分: '评分',
  工作流: '工作流',
  图表: '图表',
  打印: '打印',
  手机预览: '手机预览',
  头像裁剪: '头像裁剪',
  时间线: '时间线',
  数字自增长: '数字自增长',
  多标签: '多标签',
  动态Meta: '动态Meta',
  动态路径参数: '动态路径参数',
  拖拽: '拖拽',
  弹窗拖拽: '弹窗拖拽',
  卡片拖拽: '卡片拖拽',
  流程图拖拽: '流程图拖拽',
  右键菜单: '右键菜单',
  列表: '列表',
  多级路由缓存: '多级路由缓存',
  '多级路由1-1': '多级路由1-1',
  '多级路由1-1-1': '多级路由1-1-1',
  加载: '加载',
  视频播放器: '视频播放器',
  编辑器: '编辑器',
  Markdown编辑器: 'Markdown编辑器',
  富文本编辑器: '富文本编辑器',
  上传: '上传',
  错误日志模拟: '错误日志模拟',
  Excel: 'Excel',
  导出Excel: '导出Excel',
  导出选中行Excel: '导出选中行Excel',
  导出合并Excel: '导出合并Excel',
  Css动画: 'Css动画',
  第三方登录: '第三方登录',
  物料源: '物料源',
  物料市场: '物料市场',
  错误页: '错误页',
  403: '403',
  404: '404',
  邮件: '邮件',
  清空消息: '清空消息',
  更多: '更多',
  关闭其他: '关闭其他',
  关闭左侧: '关闭左侧',
  关闭右侧: '关闭右侧',
  关闭全部: '关闭全部',
  退出登录: '退出登录',
  登录: '登录',
  验证码: '验证码',
  注册: '注册',
  主题配置: '主题配置',
  主题: '主题',
  常用设置: '常用设置',
  其它设置: '其它设置',
  分栏布局时生效: '分栏布局时生效',
  标签开启时生效: '标签开启时生效',
  '布局配置仅在电脑视窗下生效，手机视窗时将默认锁定为纵向布局':
    '布局配置仅在电脑视窗下生效，手机视窗时将默认锁定为纵向布局',
  默认: '默认',
  海洋之心: '海洋之心sss',
  绿荫草场: '绿荫草场',
  碰触纯白: '碰触纯白',
  月上重火: '月上重火',
  蓝黑: '蓝黑',
  蓝白: '蓝白',
  绿黑: '绿黑',
  绿白: '绿白',
  红黑: '红黑',
  红白: '红白',
  渐变: '渐变',
  布局: '布局',
  分栏: '分栏',
  纵向: '纵向',
  横向: '横向',
  综合: '综合',
  常规: '常规',
  浮动: '浮动',
  菜单背景: '菜单背景',
  分栏风格: '分栏风格',
  箭头: '箭头',
  头部固定: '头部固定',
  固定: '固定',
  不固定: '不固定',
  标签: '标签',
  标签风格: '标签风格',
  标签图标: '标签图标',
  卡片: '卡片',
  灵动: '灵动',
  圆滑: '圆滑',
  国际化: '国际化',
  进度条: '进度条',
  刷新: '刷新',
  通知: '通知',
  全屏: '全屏',
  搜索: '搜索',
  开启: '开启',
  关闭: '关闭',
  随机换肤: '随机换肤',
  购买源码: '购买源码',
  拷贝源码: '拷贝源码',
  清理缓存: '清理缓存',
  恢复默认: '恢复默认',
  保存: '保存',
  欢迎来到: '欢迎来到',
  请输入用户名: '请输入用户名',
  请输入密码: '请输入密码',
  用户名不能为空: '用户名不能为空',
  密码不能少于6位: '密码不能少于6位',
  请输入正确的手机号: '请输入正确的手机号',
  请输入手机号: '请输入手机号',
  请输入手机验证码: '请输入手机验证码',
  获取验证码: '获取验证码',
}