/*
 * @Author: Custer
 * @Date: 2021-11-03 12:19:09
 * @LastEditors: zhongzhaoli 525966315@qq.com
 * @LastEditTime: 2022-10-26 19:39:56
 * @Description: file content
 */
module.exports = {
  // 开发以及部署时的URL
  publicPath: "/",
  // 开发环境每次保存时是否输出为eslint编译警告
  lintOnSave: false
}