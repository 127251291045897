/*
 * @Author: Custer
 * @Date: 2021-11-08 14:49:17
 * @LastEditors: zhongzhaoli 525966315@qq.com
 * @LastEditTime: 2023-05-18 20:02:18
 * @Description: file content
 */
import request from "@/utils/request";

export function login(data) {
  return request({
    url: "/user/login",
    method: "post",
    data,
  });
}

export function getUserInfo() {
  return request({
    url: "/user/userInfo",
    method: "get"
  });
}

export function getList(params){
  return request({
    url: "/user/getList",
    method: "get",
    params
  })
}

export function getItem(id){
  return request({
    url: `/user/getItem/${id}`,
    method: "get"
  })
}

export function editUser(data){
  return request({
    url: "/user/edit",
    method: "post",
    data
  })
}

export function updatePassword(data){
  return request({
    url: "/user/updatePassword",
    method: "post",
    data
  })
}

export function delUser(ids){
  return request({
    url: "/user/deleteUser",
    method: "post",
    data: {ids}
  })
}

export function changeStatus(id, status){
  return request({
    url: "/user/changeStatus",
    method: "post",
    data: { id, status }
  })
}

export function selectRole(data){
  return request({
    url: "/user/selectRole",
    method: "post",
    data
  })
}

export function selectSchool(data){
  return request({
    url: "/user/selectSchool",
    method: "post",
    data
  })
}

export function editInfoForMySelf(data){
  return request({
    url: "/user/editForMySelf",
    method: "post",
    data
  })
}

export function changePasswordForMySelf(data){
  return request({
    url: "/user/changePasswordForMySelf",
    method: "post",
    data
  })
}