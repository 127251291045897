<!--
 * @Author: Custer
 * @LastEditors: zhongzhaoli 525966315@qq.com
 * @Date: 2022-06-16 18:04:05
 * @LastEditTime: 2022-10-20 00:14:49
 * @Description: file content
-->
<template>
  <div class="CusterUpload">
    <div class="flex">
      <div class="filesImageList">
        <template v-for="(item, index) in fileList">
          <div
            class="item"
            :class="{
              imageItem: mediaType === 'IMAGE' || uploadedMediaType === 'IMAGE',
              videoItem: mediaType === 'VIDEO' || uploadedMediaType === 'VIDEO',
            }"
            :style="itemStyleReturn"
            :key="index"
          >
            <img
              :src="item"
              v-if="mediaType === 'IMAGE' || uploadedMediaType === 'IMAGE'"
              alt=""
            />
            <video
              :src="item"
              v-if="mediaType === 'VIDEO' || uploadedMediaType === 'VIDEO'"
              controls
            ></video>
            <div
              class="imageHandle"
              v-show="mediaType === 'IMAGE' || uploadedMediaType === 'IMAGE'"
            >
              <div @click="showPicture(index)" v-show="!noView">
                <i class="el-icon-zoom-in"></i>
              </div>
              <div @click="deletePicture(item)">
                <i class="el-icon-delete"></i>
              </div>
            </div>
            <div
              class="videoHandle"
              v-show="mediaType === 'VIDEO' || uploadedMediaType === 'VIDEO'"
            >
              <i class="el-icon-delete" @click="deletePicture(item)"></i>
            </div>
          </div>
        </template>
      </div>
      <div v-loading="uploadLoading" v-show="showAddBox">
        <el-upload
          :action="uploadActionUrl"
          list-type="picture-card"
          :show-file-list="false"
          :headers="uploadHeaders"
          :before-upload="beforeUpload"
          :on-success="uploadSuccess"
          :on-error="uploadError"
          :style="itemStyleReturn"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * 支持单图，多图，视频和图片混合 三种上传方式
 */
const LIMIT_TYPE = {
  MIXIN: {
    type: ["video/mp4", "image/jpeg", "image/png"],
    errorMsg: "请上传PNG、JPG、JPEG格式的图片，MP4格式的视频",
  },
  IMAGE: {
    type: ["image/jpeg", "image/png", "image/gif"],
    errorMsg: "请上传PNG、JPG、JPEG, GIF格式的图片",
  },
  VIDEO: {
    type: ["video/mp4"],
    errorMsg: "请上传MP4格式的视频",
  },
};

const TYPE_TRANSFORM = {
  image: "IMAGE",
  video: "VIDEO",
};
const SUCCESS_CODE = 200;
const SUCCESS_URL_KEY = "data";
const ERROR_MSG_KEY = "message";

const DEFAULT_WIDTH = "148px";
const DEFAULT_HEIGHT = "148px";
const DEFAULT_RADIUS = "5px";
export default {
  name: "CusterUpload",
  props: {
    mediaType: {
      type: String,
      require: true,
    },
    imageMaxLength: {
      type: Number,
      default: 3,
    },
    videoMaxLength: {
      type: Number,
      default: 1,
    },
    uploadActionUrl: {
      type: String,
      require: true,
    },
    uploadHeaders: {
      type: Object,
      default: () => {
        return {};
      },
    },
    uploadedType: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
    radius: {
      type: String,
      default: DEFAULT_RADIUS,
    },
    noView: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      fileList: [],
      uploadedMediaType: null,
      uploadLoading: false,
    };
  },
  watch: {
    fileList: {
      deep: true,
      handler(nV) {
        this.$emit("input", nV);
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler(nV) {
        this.fileList = nV;
      },
    },
    uploadedType: {
      immediate: true,
      handler(nV) {
        this.uploadedMediaType = nV;
      },
    },
  },
  computed: {
    // 判断是否显示添加图标
    showAddBox() {
      if (this.mediaType === "IMAGE")
        return (this?.fileList?.length || 0) < this.imageMaxLength;
      if (this.mediaType === "VIDEO")
        return (this?.fileList?.length || 0) < this.videoMaxLength;
      if (this.mediaType === "MIXIN") {
        if (this.uploadedMediaType === null) return true;
        else {
          if (this.uploadedMediaType === "IMAGE")
            return (this?.fileList?.length || 0) < this.imageMaxLength;
          if (this.uploadedMediaType === "VIDEO")
            return (this?.fileList?.length || 0) < this.videoMaxLength;
        }
      }
      return false;
    },
    // 样式
    itemStyleReturn() {
      let style = { "border-radius": this.radius || DEFAULT_RADIUS };
      if (this.width !== null) {
        style.width = this.width;
      } else {
        if (this.uploadedMediaType === "VIDEO") style.width = "300px";
        else style.width = DEFAULT_WIDTH;
      }
      if (this.height !== null) style.height = this.height;
      else style.height = DEFAULT_HEIGHT;
      return style;
    },
  },
  methods: {
    // 上传前做图片类型判断
    beforeUpload(file) {
      try {
        const type = file.type.split("/")[0];
        // 已上传了图片后续只能上传图片 视频同理
        if (this.uploadedMediaType === "IMAGE") {
          if (type !== "image") {
            this.$message.error(LIMIT_TYPE.IMAGE.errorMsg);
            return Promise.reject();
          }
        }
        if (this.uploadedMediaType === "VIDEO") {
          if (type !== "video") {
            this.$message.error(LIMIT_TYPE.VIDEO.errorMsg);
            return Promise.reject();
          }
        }
        // 初次上传
        if (this.uploadedMediaType === null) {
          if (!LIMIT_TYPE[this.mediaType].type.includes(file.type)) {
            this.$message.error(LIMIT_TYPE[this.mediaType].errorMsg);
            return Promise.reject();
          }
        }
        this.uploadLoading = true;
      } catch (err) {
        this.$message.error("请上传正确的图片或视频文件");
        return Promise.reject();
      }
    },
    // 成功后操作
    successHandle(response, type) {
      this.fileList.push(response[SUCCESS_URL_KEY]);
      this.uploadedMediaType = TYPE_TRANSFORM[type];
      this.uploadLoading = false;
      this.$emit("on-success", this.fileList, this.uploadedMediaType);
    },
    // 上传成功回调
    uploadSuccess(response, file) {
      console.log(response);
      if (response.code === SUCCESS_CODE) {
        try {
          const type = file.raw.type.split("/")[0];
          if (type === "image") {
            let image = new Image();
            image.onload = () => {
              this.successHandle(response, type);
            };
            image.src = response[SUCCESS_URL_KEY];
          } else {
            this.successHandle(response, type);
          }
        } catch (err) {
          this.$message.error("请上传正确的图片或视频文件");
          throw err;
        }
      } else {
        this.uploadLoading = false;
        this.$message.error(response[ERROR_MSG_KEY]);
      }
    },
    // 错误回调
    uploadError(err) {
      let myError = err.toString(); //转字符串
      myError = myError.replace("Error: ", ""); //去掉前面的
      myError = JSON.parse(myError); //转对象
      this.$message.error(myError.msg || "上传失败，请稍后重试");
      this.uploadLoading = false;
    },
    // 预览图片
    showPicture(index) {
      this.$viewerApi({
        images: this.fileList,
        options: {
          initialViewIndex: index,
        },
      });
    },
    // 删除
    deletePicture(item) {
      let index = this.fileList.findIndex(v => v === item);
      if (index >= 0) {
        this.fileList.splice(index, 1);
      }
      if (this.fileList.length === 0) {
        this.uploadedMediaType = null;
      }
      this.$nextTick(() => {
        this.$emit("on-remove", this.fileList, this.uploadedMediaType);
      });
    },
    // 检查上传是否完成
    validateLoading() {
      return !this.uploadLoading;
    },
  },
};
</script>
<style lang="scss">
.CusterUpload {
  & .el-upload {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
  }
}
</style>
<style lang='scss' scoped>
.CusterUpload {
  & > .flex {
    display: flex;
    & > .filesImageList {
      display: flex;
      & > .item {
        border: 1px dashed #c0ccda;
        &:not(:last-child) {
          margin-right: 10px;
        }
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &.imageItem {
          width: 100%;
          height: 100%;
          &:hover {
            & > .imageHandle {
              transform: translateX(0px);
            }
          }
        }
        &.videoItem {
          width: 300px;
          height: 100%;
          &:hover {
            & > .videoHandle {
              transform: translateX(0px);
            }
          }
        }
        & > .imageHandle {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s;
          transform: translateX(100%);
          & > div {
            padding: 0 10px;
            color: #fff;
            font-size: 24px;
          }
        }
        & > .videoHandle {
          width: 50px;
          height: 50px;
          background-color: rgba(0, 0, 0, 0.8);
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 24px;
          transition: all 0.3s;
          transform: translateX(50px);
        }
        & > img {
          width: 100%;
          height: 100%;
          vertical-align: bottom;
        }
        & > video {
          width: 100%;
          height: 100%;
          vertical-align: bottom;
        }
        &:not(:last-chil) {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>