/*
 * @Author: Custer
 * @Date: 2021-10-29 14:29:00
 * @LastEditors: Custer
 * @LastEditTime: 2021-11-05 12:02:03
 * @Description: file content
 */
module.exports = {
  首页: 'Home',
  看板: 'Dashboard',
  工作台: 'Workbench',
  更新日志: 'Change log',
  配置: 'Settings',
  个人中心: 'User center',
  用户管理: 'User management',
  角色管理: 'Role management',
  部门管理: 'Department management',
  菜单管理: 'Menu management',
  系统日志: 'System log',
  组件: 'Part',
  其他: 'Other',
  角色权限: 'Roles',
  外链: 'External links',
  图标: 'Icon',
  常规图标: 'Awesome icon',
  小清新图标: 'Remix icon',
  图标选择器: 'Icon selector',
  表格: 'Table',
  综合表格: 'Comprehensive table',
  行内编辑表格: 'Inline edit table',
  自定义表格: 'Custom table',
  表单: 'Form',
  综合表单: 'Comprehensive form',
  分步表单: 'Step form',
  按钮: 'Button',
  文字链接: 'Link',
  单选框: 'Radip',
  多选框: 'Checkbox',
  输入框: 'Input',
  计数器: 'Input number',
  选择器: 'Select',
  开关: 'Switch',
  滑块: 'Slider',
  时间选择器: 'Time picker',
  日期选择器: 'Date picker',
  日期时间选择器: 'Date time picker',
  评分: 'rate',
  工作流: 'Workflow',
  图表: 'Echarts',
  打印: 'Print',
  手机预览: 'Mobile preview',
  头像裁剪: 'Head cropper',
  时间线: 'Timeline',
  数字自增长: 'Count',
  多标签: 'Tabs',
  动态Meta: 'Dynamic meta',
  动态路径参数: 'Dynamic segment',
  拖拽: 'Drag',
  弹窗拖拽: 'Dialog drag',
  卡片拖拽: 'Card drag',
  流程图拖拽: 'Flowsheet drag',
  右键菜单: 'Contextmenu',
  列表: 'List',
  多级路由缓存: 'Menu1',
  '多级路由1-1': 'Menu1-1',
  '多级路由1-1-1': 'Menu1-1-1',
  加载: 'Loading',
  视频播放器: 'Player',
  编辑器: 'Editor',
  Markdown编辑器: 'Markdown editor',
  富文本编辑器: 'Rich text editor',
  上传: 'Upload',
  错误日志模拟: 'Log',
  Excel: 'Excel',
  导出Excel: 'Export excel',
  导出选中行Excel: 'Export selected excel',
  导出合并Excel: 'Export merge header excel',
  Css动画: 'Cssfx',
  第三方登录: 'Social login',
  物料源: 'Material',
  物料市场: 'Material market',
  错误页: 'Error',
  403: '403',
  404: '404',
  邮件: 'Email',
  清空消息: 'Clear message',
  更多: 'More',
  关闭其他: 'Close other',
  关闭左侧: 'Close left',
  关闭右侧: 'Close right',
  关闭全部: 'Close all',
  退出登录: 'Logout',
  登录: 'Login',
  验证码: 'Verification code',
  注册: 'register',
  主题配置: 'Theme',
  主题: 'Theme',
  常用设置: 'Common settings',
  其它设置: 'Other settings',
  分栏布局时生效: 'Column layout takes effect',
  标签开启时生效: 'Effective when the label is opened',
  '布局配置仅在电脑视窗下生效，手机视窗时将默认锁定为纵向布局':
    'The layout configuration only takes effect in the computer window,the vertical layout will be locked in the mobile window by default',
  默认: 'Default',
  海洋之心: 'Ocean',
  绿荫草场: 'Green',
  碰触纯白: 'White',
  月上重火: 'Red',
  蓝黑: 'Blue black',
  蓝白: 'Blue white',
  绿黑: 'Green black',
  绿白: 'Green white',
  红黑: 'Red black',
  红白: 'Red white',
  渐变: 'Ocean',
  布局: 'Layouts',
  分栏: 'Column',
  纵向: 'Vertical',
  横向: 'Horizontal',
  综合: 'Comprehensive',
  常规: 'Common',
  浮动: 'Float',
  菜单背景: 'Background',
  分栏风格: 'Column style',
  箭头: 'Arrow',
  头部固定: 'Header',
  固定: 'Fixed',
  不固定: 'No fixed',
  标签: 'Tabs',
  标签风格: 'Tabs style',
  标签图标: 'Tabs Icon',
  卡片: 'Card',
  灵动: 'Smart',
  圆滑: 'Smooth',
  国际化: 'Language',
  进度条: 'Progress',
  刷新: 'Refresh',
  通知: 'Notice',
  全屏: 'Full screen',
  搜索: 'Search',
  开启: 'Open',
  关闭: 'Close',
  随机换肤: 'Random',
  购买源码: 'Buy',
  拷贝源码: 'Code',
  恢复默认: 'Defalut',
  清理缓存: 'Claer',
  保存: 'Save',
  欢迎来到: 'Welcome to',
  请输入用户名: 'Please enter one user name',
  请输入密码: 'Please input a password',
  用户名不能为空: 'The user name cannot be empty',
  密码不能少于6位: 'The password cannot be less than 6 digits',
  请输入正确的手机号: 'Please enter the correct mobile phone number',
  请输入手机号: 'Please enter your mobile phone number',
  请输入手机验证码: 'Please input the mobile phone verification code',
  获取验证码: 'Get captcha',
}