/*
 * @Author: Custer
 * @Date: 2021-11-24 17:20:11
 * @LastEditors: zhongzhaoli 525966315@qq.com
 * @LastEditTime: 2022-10-22 03:13:56
 * @Description: file content
 */
import store from '@/store'

/**
 * 字符权限校验
 * @param {Array} value 校验值
 * @returns {Boolean}
 */
export function checkPermi(value = []) {
  // 不是数组 不是对象 或者是数组但是数组为空 return false;
  if (Object.prototype.toString.call(value) != '[object Object]' && (Object.prototype.toString.call(value) != '[object Array]' || value.length <= 0)) return false;
  let permissionDatas = Object.prototype.toString.call(value) === '[object Object]' ? value.permissions : value;
  let type = Object.prototype.toString.call(value) === '[object Object]' ? value.type || 'some' : 'some';
  const permissions = store.getters["acl/permission"]

  const all_permission = "*:*:*";

  if (permissions.includes(all_permission) && type !== "everyNo") return true;
  if (permissions.includes(all_permission) && type === "everyNo") return false;

  let hasPermission = false;

  if (type === "some") {
    hasPermission = permissions.some(permission => {
      return permissionDatas.includes(permission)
    })
  }
  if (type === "every") {
    hasPermission = permissions.every(permission => {
      return permissionDatas.includes(permission)
    })
  }
  if (type === "everyNo"){
    let briefVal = true
    for(let i of permissions){
      if(permissionDatas.includes(i)){
        briefVal = false;
        break;
      }
    }
    hasPermission = briefVal;
  }

  if (!hasPermission) {
    return false
  }
  return true
}

/**
 * 角色权限校验
 * @param {Array} value 校验值
 * @returns {Boolean}
 */
export function checkRole(value) {
  if (value && value instanceof Array && value.length > 0) {
    const roles = store.getters["acl/role"]
    const permissionRoles = value
    const super_admin = "ADMIN";

    const hasRole = roles.some(role => {
      return super_admin === role || permissionRoles.includes(role)
    })

    if (!hasRole) {
      return false
    }
    return true
  } else {
    console.error(`need roles! Like checkRole="['admin','editor']"`)
    return false
  }
}
