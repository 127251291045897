/*
 * @Author: Custer
 * @Date: 2021-11-05 14:32:27
 * @LastEditors: zhongzhaoli 525966315@qq.com
 * @LastEditTime: 2022-10-25 22:19:37
 * @Description: file content
 */
import Vue from 'vue';
import { Notification, Message } from 'element-ui'
import { isEmpty } from '@/utils/validate';
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer)

Vue.prototype.isEmpty = isEmpty
Vue.prototype.$baseNotify = (
  message,
  title,
  type = 'success',
  position = 'top-right',
  duration = 3000
) => {
  Notification({
    title,
    message,
    type,
    duration,
    position,
  })
}

Vue.prototype.$baseMessage = (
  message,
  type = 'info',
) => {
  Message({
    message,
    type,
    duration: 3000,
  })
}

/**
 * @description 全局事件总线
 */
Vue.prototype.$baseEventBus = new Vue();