<!--
 * @Author: Custer
 * @Date: 2021-10-29 14:23:48
 * @LastEditors: Custer
 * @LastEditTime: 2021-11-03 12:21:10
 * @Description: file content
-->
<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: "App",
  data() {
    return {}
  },
  methods: {
  }
};
</script>
