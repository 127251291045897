<!--
 * @Author: Custer
 * @Date: 2021-11-05 16:35:56
 * @LastEditors: Custer
 * @LastEditTime: 2021-11-29 14:10:00
 * @Description: file content
-->
<template>
  <div class="custer-app-main" id="custer-app-main">
    <section>
      <CusterRouterView/>
    </section>
  </div>
</template>
<script>
export default {
  name: "CusterAppMain",
  data(){
    return {}
  }
};
</script>
<style lang='scss' scoped>
</style>