<!--
 * @Author: Custer
 * @Date: 2021-12-03 11:07:50
 * @LastEditors: Custer
 * @LastEditTime: 2021-12-03 14:13:33
 * @Description: file content
-->
<template>
  <div class="custer-section">
    <slot />
  </div>
</template>
<script>
export default {
  name: "CusterSection",
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>

</style>
