/*
 * @Author: Custer
 * @Date: 2021-11-24 17:04:07
 * @LastEditors: zhongzhaoli 525966315@qq.com
 * @LastEditTime: 2022-10-22 00:27:46
 * @Description: file content
 */
import Vue from 'vue';
import { checkPermi } from '@/utils/permission'

Vue.directive('permissions', {
  inserted(el, binding){
    const { value } = binding;
    if(value){
      if (!checkPermi(value)) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  }
})